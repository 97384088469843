<template>
  <div>
    <v-main>
      <v-row
        align="center"
        align-content="center"
      >
        <v-col
          cols="12"
          md="6"
          sm="6"
          align-self="center"
        >
          <v-img
            src="@/assets/img/yellow_pet_celebration.gif"
            alt="logo"
            class="step-finish__img"
            contain
          />
        </v-col>
        <v-col
          class="step-finish__text"
          cols="12"
          md="6"
          sm="6"
        >
          <h2>
            En las próximas 24hs recibirás <br>
            el dinero en tu cuenta*.
          </h2>
          <h5 class="subtitle">
            TE HEMOS ENVIADO UN MAIL DE BIENVENIDA.<br>
            *DEPÓSITO SUJETO A VERIFICACIÓN DE DATOS.
          </h5>
        </v-col>
      </v-row>
    </v-main>
  </div>
</template>

<script>
import { homeRoute } from "@/helper/homeRoute";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AutogestionDatosSolicitudTerminada",
  data: () => ({
    valid: true,
  }),
  mounted() {
    this.setStyleStepper("solicitud__stepper-img");
    this.$gtm.trackEvent({
      noninteraction: false,
      event: `${this.getPreffix()}_CREDIT_ORDER`,
      id_person: this.$store.getters.getUserForm,
    });
    setTimeout(this.next, 6000);
  },
  methods: {
    ...mapGetters(["getPreffix"]),
    ...mapActions(["setStyleStepper"]),
    next() {
      this.$router.replace({ name: homeRoute.HOME });
    },
  }
};
</script>
<style>


</style>